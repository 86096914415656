export const common = {
  VIEW: "Просмотр",
  EDIT: "Редактировать",
  REMOVE: "Удалить",
  COPY: "Скопировать",
  REQUIRED: "Поле обязательно к заполнению",
  EMAIL: "Адрес электронной почты должен соответствовать формату a@a.aa",
  PHONE: "Номер телефона должен соответствовать формату +7(999) 999-99-99",
  SNILS: "СНИЛС должен соответствовать формату 000-000-000-00",
  FILE_EXTENSION: "Некорректный формат. Допустимые форматы:",
  FILE_SIZE: "Размер файла не должен превышать ",
  INVALID_DATE_FORMAT: "Недопустимый формат даты",
};
