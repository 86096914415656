import { ConclusionAppealQuery } from "generated/operations";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";

export type TConclusionDoctorLoaded = {
  isLoadedNarcology: boolean;
  narcologyId: number;
  isLoadedPsychiatris: boolean;
  psychiatrisId: number;
};

const emptyUploaded: TConclusionDoctorLoaded = {
  isLoadedNarcology: false,
  narcologyId: 0,
  isLoadedPsychiatris: false,
  psychiatrisId: 0,
};

export const conclusionDoctorValidateUploaded = (
  isNarcology: boolean,
  isPsychiatris: boolean,
  data: ConclusionAppealQuery | undefined
): TConclusionDoctorLoaded => {
  if (data === undefined || data.conclusionAppeals?.totalCount === 0)
    return emptyUploaded;

  let isLoadedNarcologyExsist = false;
  let narcologyExsistId: number = 0;
  let isLoadedPsychiatrisExsist = false;
  let psychiatrisExsistId: number = 0;

  const conclusions = data.conclusionAppeals?.items!;
  for (let index = 0; index < conclusions.length; index++) {
    const conclusion = conclusions[index];
    if (
      isNarcology &&
      conclusion.name === ConclusionsName.Narcology &&
      conclusion.conclusionFile === null
    ) {
      isLoadedNarcologyExsist = true;
      narcologyExsistId = conclusion.id;
    }

    if (
      isPsychiatris &&
      conclusion.name === ConclusionsName.Psychiatrist &&
      conclusion.conclusionFile === null
    ) {
      isLoadedPsychiatrisExsist = true;
      psychiatrisExsistId = conclusion.id;
    }
  }

  return {
    isLoadedNarcology: isLoadedNarcologyExsist,
    narcologyId: narcologyExsistId,
    isLoadedPsychiatris: isLoadedPsychiatrisExsist,
    psychiatrisId: psychiatrisExsistId,
  };
};
