import {
  ConclusionAppealQuery,
  ConclusionAppealType,
} from "generated/operations";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";

export const conclusionTherapistValidateCreate = (
  data: ConclusionAppealQuery | undefined
): boolean => {
  if (data === undefined || data.conclusionAppeals?.totalCount === 0)
    return false;

  let exsistNarcology: boolean = false;
  let exsistPsychiatris: boolean = false;

  const conclusions = data.conclusionAppeals?.items!;
  for (let index = 0; index < conclusions.length; index++) {
    const conclusion = conclusions[index];

    if (
      conclusion.name === ConclusionsName.Therapist &&
      conclusion.type === ConclusionAppealType.CONCLUSION_THERAPIST
    ) {
      return false;
    }

    if (
      conclusion.name === ConclusionsName.Narcology &&
      conclusion.type === ConclusionAppealType.CONCLUSION_DOCTOR &&
      conclusion.isActual
    ) {
      exsistNarcology = true;
    }

    if (
      conclusion.name === ConclusionsName.Psychiatrist &&
      conclusion.type === ConclusionAppealType.CONCLUSION_DOCTOR &&
      conclusion.isActual
    ) {
      exsistPsychiatris = true;
    }
  }

  return exsistNarcology && exsistPsychiatris;
};
