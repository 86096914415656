import { useCallback, useState } from "react";
import { useField } from "formik";
import { Switch } from "@genome-frontend/uikit/build/lib/uikit";

export type TSwitchField = {
  name: string;
  label?: string;
  required?: boolean;
  isOn?: boolean;
};

export const SwitchField = ({ label, name, isOn, ...props }: TSwitchField) => {
  const [field, , { setValue }] = useField(name);
  const [checked, setChecked] = useState(isOn ?? false);

  const onChange = useCallback(
    (evt: any) => {
      setChecked(evt.target.checked);
      setValue(evt.target.checked);
    },
    [setChecked, setValue]
  );

  return (
    <Switch
      key={label}
      onChange={onChange}
      checked={checked}
      label={label}
      name={name}
      {...props}
    />
  );
};
