import React, { FC } from "react";
import { Box, Grid } from "@genome-frontend/uikit/build/lib/uikit";
import { TokenHelper } from "TokenHelper";
import { UserRole } from "types/UserRole";
import { ConclusionDoctorCreate } from "../ConclusionDoctor";
import { ConclusionTherapistCreate } from "../ConclusionTherapist";
import { AppealViewGrid } from "../AppealViewGrid/AppealViewGrid";
import { useAppealViewDataGrid } from "pages/AppealPage/hooks/useAppealViewDataGrid/useAppealViewDataGrid";

interface AppealViewProps {
  clientId: string;
  appealId: number;
}

const roleName = TokenHelper.getCurrentUserRole();
const employeeId = TokenHelper.getCurrentUserId();
const organizationId = TokenHelper.getOrganizationId();
const isDoctor = roleName === UserRole.Doctor;
const isTherapist = roleName === UserRole.Therapist;

export const AppealView: FC<AppealViewProps> = ({ clientId, appealId }) => {
  const gridData = useAppealViewDataGrid({
    variablesTransformer: (variables) => {
      variables.id = appealId;
      variables.roleName = roleName!;
      return variables;
    },
  });

  return (
    <>
      <Box className={"box_header"}>
        <Box className={"box_center"}></Box>
        <Box className={"box_center"}></Box>
      </Box>
      <Grid alignItems="flex-start" container spacing={2}>
        <Grid xs={12} item>
          {isDoctor && (
            <ConclusionDoctorCreate
              employeeId={employeeId!}
              organizationId={organizationId}
              appealId={appealId}
              roleName={roleName}
            />
          )}
          {isTherapist && (
            <ConclusionTherapistCreate
              organizationId={organizationId}
              employeeId={employeeId!}
              appealId={appealId}
            />
          )}
        </Grid>
      </Grid>
      <AppealViewGrid {...{ gridData }} />
    </>
  );
};
