export const ConclusionFileExt = ".pdf";

export const OrganizationStampAcceptableExt = ".jpeg,.jpg,.bmp,.png";
export const OrganizationStampAcceptableMimeTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/bmp",
];

export const OrganizationLicenseAcceptableExt = ".pdf";
export const OrganizationLicenseAcceptableMimeTypes = ["application/pdf"];
