import { locale } from "locale";
import { TConclusionAppealDataGridResult } from "./useAppealViewDataGrid";
import { GridRowParams } from "@mui/x-data-grid";
import React from "react";
import {
  FileCopyOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
} from "@mui/icons-material";
import getColumnActions from "components/DataGrid/getColumnActions";
import { ConclusionAppealType } from "generated/operations";
import { TokenHelper } from "TokenHelper";
import { UserProfile } from "types/UserProfile";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";
import getColumnIconActions from "components/DataGrid/getColumnIconActions";
import { useAppealViewRemoveConsolution } from "./useAppealViewRemoveConsolution";
import { useAppealViewRemoveConsolutionTherapist } from "./useAppealViewRemoveConsolutionTherapist";
import { IconLinkDownloadFile } from "components/LinkDownloadFile/IconLinkDownloadFile";
import { LoadConclusionFile } from "../../components/ConclusionDoctor/LoadConclusionFile/LoadConclusionFileDoctor";
import { LoadConclusionFileTherapist } from "../../components/ConclusionTherapist/LoadConclusionFile/LoadConclusionFileTherapist";
import { IconLinkDownloadForm } from "components/LinkDownloadFile/IconLinkDownloadForm";

export const useAppealViewColumns = (
  gridData: TConclusionAppealDataGridResult
) => {
  const getUploadOrChangeFileIconData = (params: GridRowParams) => {
    if (params.row.conclusionFile) {
      return {
        icon: React.createElement(FileCopyOutlined, { cursor: "pointer" }),
        title: locale.conclusion.changeFile,
      };
    } else {
      return {
        icon: React.createElement(FileUploadOutlined, { cursor: "pointer" }),
        title: locale.conclusion.uploadFile,
      };
    }
  };

  const removeConclusionDoctor = useAppealViewRemoveConsolution();
  const removeConclusionTherapist = useAppealViewRemoveConsolutionTherapist();

  const getDownloadIcon = (params: GridRowParams) => {
    if (params.row.conclusionFile) {
      const iconData = {
        fileData: params.row.conclusionFile?.file!,
        fileName: params.row.conclusionFile?.fileName!,
        icon: React.createElement(FileDownloadOutlined),
        popUpMessage: locale.conclusion.downloadFile,
      };
      return IconLinkDownloadFile(iconData);
    } else {
      const iconData = {
        icon: React.createElement(FileDownloadOutlined),
        popUpMessage: locale.conclusion.downloadFile,
        disabled: true,
      };
      return IconLinkDownloadFile(iconData);
    }
  };

  const getDownloadForm = (params: GridRowParams) => {
    let isDisabled: boolean = true;

    if (
      (TokenHelper.checkIsNarcologyProfile() &&
        params.row.name === ConclusionsName.Narcology) ||
      (TokenHelper.checkIsPsychiatristProfile() &&
        params.row.name === ConclusionsName.Psychiatrist) ||
      (TokenHelper.checkIsTherapistProfile() &&
        params.row.name === ConclusionsName.Therapist)
    ) {
      isDisabled = false;
    }

    const iconData = {
      clientId: params.row.patientId,
      conclusionId: Math.abs(params.row.id),
      popUpMessage: locale.conclusion.downloadFile,
      disabled: isDisabled,
    };

    return IconLinkDownloadForm(iconData);
  };

  const getDisabledDownload = (params: GridRowParams) => {
    return !params.row.conclusionFile;
  };

  const columnActions = getColumnActions({
    columnActions: [
      {
        label: locale.REMOVE,
        onClick: (params) => {
          const id: number = Math.abs(Number(params.row.id));
          if (params.row.type === ConclusionAppealType.CONCLUSION_DOCTOR) {
            removeConclusionDoctor.removeConclusion(id);
          }
          if (params.row.type === ConclusionAppealType.CONCLUSION_THERAPIST) {
            removeConclusionTherapist.removeConclusion(id);
          }
        },
      },
    ],
  });

  const uploadFile = (params: GridRowParams) => {
    const iconData = getUploadOrChangeFileIconData(params);
    const message =
      iconData.title === locale.conclusion.uploadFile
        ? locale.conclusion.successUploadFile
        : locale.conclusion.successChangeFile;

    const conclusionFileProps = {
      id: Math.abs(params.row.id),
      icon: iconData.icon,
      title: iconData.title,
      message: message,
    };

    if (params.row.name == ConclusionsName.Therapist)
      return LoadConclusionFileTherapist(conclusionFileProps);
    else return LoadConclusionFile(conclusionFileProps);
  };

  const getDisabledUpload = (params: GridRowParams) => {
    const profilesString = TokenHelper.getCurrentUserProfiles();
    const profiles = profilesString?.split(",");

    if (!profiles) {
      return true;
    }

    if (
      profiles.includes(UserProfile.Narcology) &&
      params.row.name == ConclusionsName.Narcology
    ) {
      return false;
    }

    if (
      profiles.includes(UserProfile.Psychiatrist) &&
      params.row.name == ConclusionsName.Psychiatrist
    ) {
      return false;
    }

    if (
      profiles.includes(UserProfile.Therapist) &&
      params.row.name == ConclusionsName.Therapist
    ) {
      return false;
    }

    return true;
  };

  const uploadButtonColumn = getColumnIconActions({
    columnIconActions: [
      {
        getIconFunc: uploadFile,
        label: locale.conclusion.uploadFile,
        getDisabledFunc: getDisabledUpload,
      },
    ],
    headerName: "Загрузить",
    fieldName: "uploadFileActions",
  });

  const downloadButtonColumn = getColumnIconActions({
    columnIconActions: [
      {
        getIconFunc: getDownloadForm,
        label: locale.conclusion.downloadFile,
      },
    ],
    headerName: "Скачать",
    fieldName: "downloadFileActions",
  });

  return gridData.gridProps.columns
    .concat(uploadButtonColumn)
    .concat(downloadButtonColumn)
    .concat(columnActions);
};
