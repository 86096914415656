import { MainLayout } from "../../components/MainLayout";
import { UsersGrid } from "./components/UsersGrid/UsersGrid";

export function UsersPage() {
  return (
    <MainLayout title="Сотрудники">
      <UsersGrid />
    </MainLayout>
  );
}
