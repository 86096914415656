import React, { FC, useState } from "react";
import {
  Button,
  DatePicker,
  Grid,
  Label,
  Modal,
  ModalContent,
  RadioGroup,
} from "@genome-frontend/uikit/build/lib/uikit";
import { useConclusionAppealQuery } from "generated/hooks";
import { RestrictionType, TherapistConclusionType } from "generated/operations";
import {
  RadioGroupProps,
  TRadioGroupOption,
} from "@genome-frontend/uikit/build/lib/uikit/RadioButton";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";
import {
  conclusionTherapistValidateSave,
  RestrictionTypeNull,
  TherapistConclusionTypeNull,
} from "./ConclusionTherapistValidateSave";
import "date-fns";
import { conclusionTherapistValidateCreate } from "./ConclusionTherapistValidateCreate";
import { DateNull } from "types/NullableTypes";
import "./ConclusionTherapist.css";
import { useCreateConclusionTherapistWithCsp } from "../../hooks/useCreateConclusionTherapistWithCsp";

const radioOptionsConclusion: TRadioGroupOption[] = [
  { id: TherapistConclusionType.GOODIN, caption: "ДА" },
  { id: TherapistConclusionType.UNFIT, caption: "НЕТ" },
];

const radioOptionsRestriction: TRadioGroupOption[] = [
  { id: RestrictionType.NONE, caption: "Нет" },
  {
    id: RestrictionType.TEMPORARILY_WORK,
    caption: "Временно допущен к труду",
  },
  {
    id: RestrictionType.NOT_TEMPORARILY_WORK,
    caption: "Временно не допущен к труду",
  },
];

interface ConclusionTherapistCreateProps {
  appealId: number;
  organizationId: number | null;
  employeeId: string;
}

export const ConclusionTherapistCreate: FC<ConclusionTherapistCreateProps> = ({
  appealId,
  organizationId,
  employeeId,
}) => {
  const conclusionData = useConclusionAppealQuery({
    variables: {
      id: appealId,
      roleName: ConclusionsName.Therapist,
      skip: null,
      take: null,
      order: null,
      where: null,
    },
  });

  const createConclusionStatus = conclusionTherapistValidateCreate(
    conclusionData.data
  );

  const [conclusionTypeCurrent, setConclusionTypeCurrent] =
    useState<TherapistConclusionTypeNull>(null);
  const [restrictionTypeCurrent, setRestrictionTypeCurrent] =
    useState<RestrictionTypeNull>(null);

  const [restrictionDateEndCurrent, setRestrictionDateEnd] =
    useState<DateNull>(null);

  const [isOpen, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setConclusionTypeCurrent(null);
    setRestrictionTypeCurrent(null);
    setRestrictionDateEnd(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const saveConclusionStatus = conclusionTherapistValidateSave(
    conclusionTypeCurrent,
    restrictionTypeCurrent,
    restrictionDateEndCurrent
  );

  const handlerConclusion: RadioGroupProps["onChange"] = (e, value) => {
    if (value !== null) {
      setConclusionTypeCurrent(value as TherapistConclusionType);
    }
  };

  const handlerRestriction: RadioGroupProps["onChange"] = (e, value) => {
    if (value !== null) {
      setRestrictionTypeCurrent(value as RestrictionType);
    }
  };

  const handlerRestrictionDateEnd = (date: DateNull) => {
    if (date !== null) {
      setRestrictionDateEnd(date);
    }
  };

  const { createConclusionTherapistWithCsp } =
    useCreateConclusionTherapistWithCsp({
      appealId,
      organizationId,
      employeeId,
      restrictionTypeCurrent,
      conclusionTypeCurrent,
      restrictionDateEndCurrent,
    });

  const handleSubmit = async () => {
    await createConclusionTherapistWithCsp(null);
    handleClose();
  };

  return (
    <>
      <Grid container sx={{ mt: 1 }}>
        <Grid xs={4} item>
          <Button
            variant="contained"
            onClick={handleOpen}
            disabled={!createConclusionStatus}
          >
            {"Выдача заключения"}
          </Button>
        </Grid>
      </Grid>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalContent
          title="Создание заключения"
          confirmButtonProps={{
            children: "Подтвердить",
            onClick: handleSubmit,
            disabled: !saveConclusionStatus,
          }}
          cancelButtonProps={{ onClick: handleClose }}
        >
          <Label>Выдать разрешение</Label>
          <form>
            <RadioGroup
              options={radioOptionsConclusion}
              onChange={handlerConclusion}
            />
          </form>
          <br></br>
          <Label>Временное ограничение</Label>
          <form>
            <RadioGroup
              options={radioOptionsRestriction}
              onChange={handlerRestriction}
            />
          </form>
          <br></br>
          <Label>Выберите дату окончания ограничения</Label>
          <form>
            <DatePicker
              onChange={handlerRestrictionDateEnd}
              value={restrictionDateEndCurrent}
            />
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
