import React, { FC } from "react";
import {
  Grid,
  Label,
  Modal,
  ModalContent,
  RadioGroup,
} from "@genome-frontend/uikit/build/lib/uikit";
import {
  useCheckCspCertLazyQuery,
  useConclusionAppealQuery,
  useCreateConclusionMutation,
  useGetUserCspLazyQuery,
} from "generated/hooks";
import { useToasts } from "components/Toast";
import { checkCspByPassword, fetchToUserCsp } from "utils/certificates";
import { TokenHelper } from "TokenHelper";
import {
  ConclusionType,
  CreateConclusionInput,
  namedOperations,
} from "generated/operations";
import { SelectDiagnosis } from "./SelectDiagnosis/SelectDiagnosis";
import {
  RadioGroupProps,
  TRadioGroupOption,
} from "@genome-frontend/uikit/build/lib/uikit/RadioButton";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";
import { Button } from "@mui/material";
import { conclusionDoctorValidateUploaded } from "./ConclusionDoctorValidateUploaded";
import { conclusionDoctorValidateSave } from "./ConclusionDoctorValidateSave";
import { conclusionDoctorValidateCreate } from "./ConclusionDoctorValidateCreate";
import { BoolNull } from "types/NullableTypes";
import { locale } from "locale";
import "./ConclusionDoctorDrawer.css";
import { GqlAutocompleteOption } from "components/GqlAutocomplete";

interface ConclusionDoctorCreateProps {
  appealId: number;
  organizationId: number | null;
  employeeId: string;
  roleName: string;
}

export const ConclusionDoctorCreate: FC<ConclusionDoctorCreateProps> = ({
  appealId,
  organizationId,
  employeeId,
  roleName,
}) => {
  const { addSuccessToast, addErrorsToast } = useToasts();

  const [selectedDiagnosisNarcologyId, setSelectedDiagnosisNarcologyId] =
    React.useState<GqlAutocompleteOption>({ id: undefined, caption: "" });
  const [selectedDiagnosisPsychiatristId, setSelectedDiagnosisPsychiatristId] =
    React.useState<GqlAutocompleteOption>({ id: undefined, caption: "" });

  const handleSelectedDiagnosisNarcology = (value: GqlAutocompleteOption) => {
    setSelectedDiagnosisNarcologyId(value);
  };

  const handleSelectedDiagnosisPsychiatrist = (
    value: GqlAutocompleteOption
  ) => {
    setSelectedDiagnosisPsychiatristId(value);
  };

  const [isOpen, setOpen] = React.useState(false);

  const isNarcologyProfile: boolean = TokenHelper.checkIsNarcologyProfile();
  const isPsychiatristProfile: boolean =
    TokenHelper.checkIsPsychiatristProfile();

  const [isNarcologyFit, setNarcologyFit] = React.useState<BoolNull>(null);
  const [isPsychiatristFit, setPsychiatristFit] =
    React.useState<BoolNull>(null);

  const handleClose = () => {
    setNarcologyFit(null);
    setPsychiatristFit(null);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const radioOptions: TRadioGroupOption[] = [
    { id: ConclusionType.GOODIN, caption: "ГОДЕН" },
    { id: ConclusionType.UNFIT, caption: "НЕ ГОДЕН" },
  ];

  const handlerNarcologyFit: RadioGroupProps["onChange"] = (e, value) => {
    if (value === ConclusionType.GOODIN) {
      setNarcologyFit(true);
    } else {
      setNarcologyFit(false);
    }
  };

  const handlerPsychiatristFit: RadioGroupProps["onChange"] = (e, value) => {
    if (value === ConclusionType.GOODIN) {
      setPsychiatristFit(true);
    } else {
      setPsychiatristFit(false);
    }
  };

  const [createConclusionMutation] = useCreateConclusionMutation();

  const conclusionData = useConclusionAppealQuery({
    variables: {
      id: appealId,
      roleName: roleName,
      skip: null,
      take: null,
      order: null,
      where: null,
    },
  });

  const createConclusionStatus = conclusionDoctorValidateCreate(
    isNarcologyProfile,
    isPsychiatristProfile,
    conclusionData.data
  );

  const saveConclusionStatus = conclusionDoctorValidateSave(
    isNarcologyProfile,
    isNarcologyFit,
    selectedDiagnosisNarcologyId !== null,
    isPsychiatristProfile,
    isPsychiatristFit,
    selectedDiagnosisPsychiatristId !== null
  );

  const conclusionUploaded = conclusionDoctorValidateUploaded(
    isNarcologyProfile,
    isPsychiatristProfile,
    conclusionData.data
  );

  const [loadUserCsp] = useGetUserCspLazyQuery();
  const [checkCsp] = useCheckCspCertLazyQuery();

  const createConclusionFromData = (
    name: string,
    diagnosisId: number | null
  ): CreateConclusionInput => {
    const conclusion: CreateConclusionInput = {
      appealId: appealId,
      name: name,
      diagnosisId: diagnosisId,
      conclusionType:
        diagnosisId === undefined
          ? ConclusionType.GOODIN
          : ConclusionType.UNFIT,
      employeeId: employeeId,
      regionCode: TokenHelper.getRegionCode(),
      medicalOrganizationCode: TokenHelper.getOrganizationCode(),
      organizationId: organizationId,
    };

    return conclusion;
  };

  const createConclusions = async () => {
    if (
      isNarcologyProfile &&
      createConclusionStatus.isNarcologyAvailable &&
      isNarcologyFit !== null
    ) {
      const { data } = await createConclusionMutation({
        variables: {
          input: createConclusionFromData(
            ConclusionsName.Narcology,
            selectedDiagnosisNarcologyId.id!
          ),
        },

        refetchQueries: [namedOperations.Query.ConclusionAppeal],
      });

      if (data?.createConclusion && data?.createConclusion.errors) {
        addErrorsToast(data?.createConclusion.errors);
        return;
      }

      if (
        data?.createConclusion &&
        "conclusionAppeal" in data?.createConclusion
      ) {
        addSuccessToast(locale.conclusion.createdNarcology);
      }
    }

    if (
      isPsychiatristProfile &&
      createConclusionStatus.isPsychiatryAvailable &&
      isPsychiatristFit !== null
    ) {
      const { data } = await createConclusionMutation({
        variables: {
          input: createConclusionFromData(
            ConclusionsName.Psychiatrist,
            selectedDiagnosisPsychiatristId.id!
          ),
        },

        refetchQueries: [namedOperations.Query.ConclusionAppeal],
      });

      if (data?.createConclusion && data?.createConclusion.errors) {
        addErrorsToast(data?.createConclusion.errors);
        return;
      }

      if (
        data?.createConclusion &&
        "conclusionAppeal" in data?.createConclusion
      ) {
        addSuccessToast(locale.conclusion.createdPsychiatrist);
      }
    }
  };

  const submitHandler = async () => {
    try {
      const thumbprint =
        localStorage.getItem("thumbprint") ??
        (await fetchToUserCsp(loadUserCsp));

      const cspPasswordCheck = await checkCspByPassword(thumbprint, checkCsp);
      if (cspPasswordCheck.result) {
        await createConclusions();
      } else {
        addErrorsToast([{ message: cspPasswordCheck.error }]);
      }
    } catch (e: any) {
      addErrorsToast([{ message: e.message }]);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Grid container sx={{ mt: 1 }}>
        <Grid xs={4} item>
          <Button
            variant="contained"
            onClick={handleOpen}
            disabled={!createConclusionStatus.isCreateConclusion}
          >
            {"Выдача заключения"}
          </Button>
        </Grid>
      </Grid>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalContent
          title="Выберите тип заключения"
          confirmButtonProps={{
            children: "Создать",
            onClick: submitHandler,
            disabled: !saveConclusionStatus,
          }}
          cancelButtonProps={{ onClick: handleClose }}
        >
          {isNarcologyProfile && createConclusionStatus.isNarcologyAvailable && (
            <>
              <Label>{locale.conclusion.conclusionNarcology}</Label>
              <form>
                <RadioGroup
                  options={radioOptions}
                  onChange={handlerNarcologyFit}
                />
              </form>

              {!isNarcologyFit && (
                <Grid xs={12} item>
                  <Label>{locale.conclusion.diagnosisMKB}</Label>
                  <SelectDiagnosis
                    onChangeDiagnosis={handleSelectedDiagnosisNarcology}
                  />
                </Grid>
              )}
            </>
          )}

          {isPsychiatristProfile &&
            createConclusionStatus.isPsychiatryAvailable && (
              <>
                <Label>{locale.conclusion.conclusionPsychiatrist}</Label>
                <form>
                  <RadioGroup
                    options={radioOptions}
                    onChange={handlerPsychiatristFit}
                  />
                </form>

                {!isPsychiatristFit && (
                  <Grid xs={12} item>
                    <Label>{locale.conclusion.diagnosisMKB}</Label>
                    <SelectDiagnosis
                      onChangeDiagnosis={handleSelectedDiagnosisPsychiatrist}
                    />
                  </Grid>
                )}
              </>
            )}
        </ModalContent>
      </Modal>
    </>
  );
};
