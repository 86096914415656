export const users: { [key: string]: string } = {
  fio: "ФИО",
  name: "Имя",
  surname: "Фамилия",
  fathersName: "Отчество",
  birthdate: "Дата рождения",
  snils: "Номер СНИЛС",
  phoneNumber: "Номер телефона",
  roleName: "Роль",
  profileNames: "Профили",
  organizationName: "Организация",
};
