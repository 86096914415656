import { ConclusionAppealQuery } from "generated/operations";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";

export type TConclusionDoctorCreateResult = {
  isCreateConclusion: boolean;
  isNarcologyAvailable: boolean;
  isPsychiatryAvailable: boolean;
};

export const conclusionDoctorValidateCreate = (
  isNarcology: boolean,
  isPsychiatris: boolean,
  data: ConclusionAppealQuery | undefined
): TConclusionDoctorCreateResult => {
  if (data === undefined || data.conclusionAppeals?.totalCount === 0)
    return {
      isCreateConclusion: true,
      isNarcologyAvailable: isNarcology,
      isPsychiatryAvailable: isPsychiatris,
    };

  let existNarcology: boolean = false;
  let existPsychiatrist: boolean = false;

  const conclusions = data.conclusionAppeals?.items!;
  for (let index = 0; index < conclusions.length; index++) {
    const conclusion = conclusions[index];
    if (conclusion.name === ConclusionsName.Narcology && conclusion.isActual)
      existNarcology = true;
    if (conclusion.name === ConclusionsName.Psychiatrist && conclusion.isActual)
      existPsychiatrist = true;
  }

  return {
    isCreateConclusion:
      (isNarcology && !existNarcology) || (isPsychiatris && !existPsychiatrist),
    isNarcologyAvailable: isNarcology && !existNarcology,
    isPsychiatryAvailable: isPsychiatris && !existPsychiatrist,
  };
};
