import { RestrictionType, TherapistConclusionType } from "generated/operations";
import { DateNull } from "types/NullableTypes";

export type TherapistConclusionTypeNull = TherapistConclusionType | null;
export type RestrictionTypeNull = RestrictionType | null;

export const conclusionTherapistValidateSave = (
  conclusion: TherapistConclusionTypeNull,
  restriction: RestrictionTypeNull,
  restrictionDateEnd: DateNull
): boolean => {
  return (
    restriction !== null &&
    (restriction === RestrictionType.NONE || restrictionDateEnd !== null) &&
    conclusion !== null
  );
};
