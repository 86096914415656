import React, { memo } from 'react';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { InputDateMask, InputDatePlaceholder } from '@genome-frontend/frmo-closed/src/constants/inputDateConsts';
import { ru } from 'date-fns/locale';
import { TextInput } from '../TextInput';
export const DatePicker = memo(({ required, onChange, placeholder = InputDatePlaceholder, error, helperText, value, fullWidth, onBlur, ...props }) => {
    return (React.createElement(LocalizationProvider, { dateAdapter: AdapterDateFns, adapterLocale: ru },
        React.createElement(MuiDatePicker, { onChange: onChange, value: value, renderInput: (params) => (React.createElement(TextInput, { ...params, error: error, required: required, fullWidth: fullWidth, inputProps: { ...params.inputProps, placeholder: 'дд.мм.гггг' }, helperText: helperText, onBlur: onBlur })), inputFormat: InputDateMask, mask: InputDatePlaceholder, label: props.label, maxDate: props.maxDate })));
});
