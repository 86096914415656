import { UserViewFragment } from "generated/operations";
import { UserUpdateValue } from "pages/UsersPage/types";

export const getInitialValues = (data: UserViewFragment): UserUpdateValue => {
  return {
    id: data.id,
    login: data.login,
    password: data.password,
    name: data.name,
    surname: data.surname,
    fathersName: data.fathersName ?? "",
    sex: data.sex,
    birthdate: data.birthdate,
    snils: data.snils,
    phoneNumber: data.phoneNumber ?? "",
    email: data.email ?? "",
    position: data.position ?? "",
    roleName: data.roleName ?? "Главный администратор",
    organization: {
      id: data.organizationId ?? undefined,
      name: data.organizationName ?? undefined,
    },
    profileIds: data.profileIds ?? [],
    hasLoginPassAccess: data.hasLoginPassAccess ?? false,
    isAccessBlocked: data.isAccessBlocked ?? false,
  };
};
