import React, { FC } from "react";
import { useNavigate, useParams } from "react-router";
import { paths } from "router/paths";
import { UserEditDrawer } from "../components/UserEditDrawer";

export const UserEditDrawerPage: FC = () => {
  const navigate = useNavigate();

  const params = useParams();

  const id = String(params.id);

  const closeHandler = () => {
    navigate(paths.users());
  };

  const successHandler = () => {
    navigate(paths.user(id));
  };

  return (
    <UserEditDrawer
      id={id}
      onSuccess={successHandler}
      onCancel={closeHandler}
    />
  );
};
