import { useCreateTherapistConclusionMutation } from "generated/hooks";
import {
  CreateTherapistConclusionInput,
  namedOperations,
  RestrictionType,
} from "generated/operations";
import { useToasts } from "components/Toast";
import { useSubmitWithCspHandler } from "utils/useSubmitWithCspHandler";
import { ConclusionsName } from "../utils/conclusionsName";
import { TokenHelper } from "TokenHelper";
import {
  RestrictionTypeNull,
  TherapistConclusionTypeNull,
} from "../components/ConclusionTherapist/ConclusionTherapistValidateSave";
import { DateNull } from "types/NullableTypes";
import { formatDate } from "utils/formatDate";
import { locale } from "locale";

type TCreateConclusionTherapistProps = {
  appealId: number;
  organizationId: number | null;
  employeeId: string;
  restrictionTypeCurrent: RestrictionTypeNull;
  conclusionTypeCurrent: TherapistConclusionTypeNull;
  restrictionDateEndCurrent: DateNull;
};

type TCreateConclusionTherapistResult = {
  createConclusionTherapistWithCsp: (values: any) => Promise<void>;
};

export const useCreateConclusionTherapistWithCsp = ({
  appealId,
  organizationId,
  employeeId,
  restrictionTypeCurrent,
  conclusionTypeCurrent,
  restrictionDateEndCurrent,
}: TCreateConclusionTherapistProps): TCreateConclusionTherapistResult => {
  const { addSuccessToast, addErrorsToast } = useToasts();

  const [createTherapistConclusionMutation] =
    useCreateTherapistConclusionMutation();

  const createConclusionFromData = (): CreateTherapistConclusionInput => {
    return {
      appealId: appealId,
      name: ConclusionsName.Therapist,
      restrictionType: restrictionTypeCurrent ?? RestrictionType.NONE,
      conclusionType: conclusionTypeCurrent!,
      employeeId: employeeId,
      regionCode: TokenHelper.getRegionCode(),
      restrictionDateEnd: restrictionDateEndCurrent
        ? formatDate(restrictionDateEndCurrent.toDateString(), "server")
        : null,
      medicalOrganizationCode: TokenHelper.getOrganizationCode(),
      organizationId: organizationId,
    };
  };

  const createConclusions = async () => {
    const { data } = await createTherapistConclusionMutation({
      variables: {
        input: createConclusionFromData(),
      },
      refetchQueries: [namedOperations.Query.ConclusionAppeal],
    });

    const errs = data?.createTherapistConclusion.errors;

    if (errs) return addErrorsToast(errs);

    if (
      data?.createTherapistConclusion &&
      "conclusionAppeal" in data?.createTherapistConclusion
    ) {
      addSuccessToast(locale.conclusion.createdTherapist);
    }
  };

  const { submitWithCspHandler } = useSubmitWithCspHandler({
    next: createConclusions,
  });

  return {
    createConclusionTherapistWithCsp: submitWithCspHandler,
  };
};
