import { BoolNull } from "types/NullableTypes";

export const conclusionDoctorValidateSave = (
  isNarcology: boolean,
  isNarcologyFit: BoolNull,
  isNarcologyDiagnosis: boolean,
  isPsychiatris: boolean,
  isPsychiatrisFit: BoolNull,
  isPsychiatrisDiagnosis: boolean
): boolean => {
  if (isNarcology && isPsychiatris) {
    if (isNarcologyFit === null && isPsychiatrisFit === null) return false;

    if ((isNarcologyFit === false && isNarcologyDiagnosis) || isNarcologyFit) {
      if (isPsychiatrisFit === null || isPsychiatrisFit) {
        return true;
      }

      if (isPsychiatrisFit === false && isPsychiatrisDiagnosis) {
        return true;
      }
    }

    if (
      (isPsychiatrisFit === false && isPsychiatrisDiagnosis) ||
      isPsychiatrisFit
    ) {
      if (isNarcologyFit === null || isNarcologyFit) {
        return true;
      }

      if (isNarcologyFit === false && isNarcologyDiagnosis) {
        return true;
      }
    }
  } else {
    if (isNarcology) {
      if (isNarcologyFit === null) return false;

      if (isNarcologyFit) return true;

      if (isNarcologyDiagnosis) return true;
    } else {
      if (isPsychiatris) {
        if (isPsychiatrisFit === null) return false;

        if (isPsychiatrisFit) return true;

        if (isPsychiatrisDiagnosis) return true;
      }
    }
  }

  return false;
};
