import React, { FC, useState } from "react";
import { Grid, Label, TextInput } from "@genome-frontend/uikit/build/lib/uikit";
import { useRoleSelect } from "./RoleSelect/RoleSelect";
import { locale } from "locale";
import { SwitchField } from "components/formFields/SwitchField";
import { Button } from "@mui/material";
import { TSelectOption } from "@genome-frontend/uikit/build/lib/uikit/Select";
import { useToasts } from "components/Toast";
import { useFormikContext } from "formik";
import { UserUpdateValue } from "pages/UsersPage/types";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { UserEditFromValidate } from "../UserEditDrawer/UserEditFromValidate";
import { generatePassword } from "utils/generatePassword";
import { ValidationTextInputField } from "components/formFields/ValidationTextInputField";
import { ValidationDatePickerField } from "components/formFields/ValidationDatePickerField";
import { MaskValidationTextInput } from "components/formFields/MaskValidationTextInput";
import { MaskExpress } from "utils/maskExpression";
import { UserRole } from "types/UserRole";
import { ValidationSelectField } from "components/formFields/ValidationSelectedField";
import { sexOptions } from "constants/selectFieldConsts";
import { OrganizationSelect } from "./OrganizationSelect/OrganizationSelect";
import { UserViewFragment } from "generated/operations";

interface UserEditFormProps {
  user?: UserViewFragment;
}

export const UserEditForm: FC<UserEditFormProps> = ({ user }) => {
  const isNew = !user;

  const { setFieldValue, touched, errors, setFieldError, setFieldTouched } =
    useFormikContext<UserUpdateValue>();

  let validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: UserEditFromValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  const roles = useRoleSelect();

  const rolesOptions = roles.rows.map((rol) => ({
    value: rol.name,
    label: rol.name || "",
  }));

  function getProfileFromRole(role_name: string): TSelectOption[] {
    const role = roles.rows.find((r) => {
      if (r.name == role_name) {
        return r;
      }
    });

    if (role!) {
      return role.profiles!.map((profile) => ({
        value: profile.id,
        label: profile.name || "",
      }));
    }

    return [];
  }

  function getInitProfileFromUser(): TSelectOption[] {
    if (!isNew) {
      const options = user?.profiles!.map((profile) => ({
        value: profile.id,
        label: profile.name || "",
      }));

      return options!;
    } else {
      return [];
    }
  }

  const [profilesOptions, setProfilesOptions] = useState<TSelectOption[]>(
    getInitProfileFromUser()
  );

  const { addSuccessToast } = useToasts();

  const [isMultuSelectProfiles, setMultuSelectProfiles] = useState(
    Boolean(user?.roleName !== UserRole.LawOfficial)
  );

  const onRoleChange = (value: any) => {
    if (!isNew) {
      const options = getProfileFromRole(value);

      setFieldValue("profileIds", [0]);
      setProfilesOptions(options);

      let firstSelect: number[] = [options[0].value as number];
      setFieldValue("profileIds", firstSelect);

      if (value === UserRole.LawOfficial) {
        setMultuSelectProfiles(false);
      } else {
        setMultuSelectProfiles(true);
      }
    }
  };

  const onGeneratePassword = () => {
    const ps = generatePassword(8);
    setFieldValue("password", ps);
  };

  const copyLoginClipboard = () => {
    const input = document.getElementById("textLoginId");
    //@ts-ignore
    navigator.clipboard.writeText(input!.value);
    addSuccessToast("Логин скопирован в буфер обмена");
  };

  const copyPasswordClipboard = () => {
    const input = document.getElementById("textPasswordId");
    //@ts-ignore
    navigator.clipboard.writeText(input!.value);
    addSuccessToast("Пароль скопирован в буфер обмена");
  };

  return (
    <Grid alignItems="flex-start" spacing={2} container>
      <Grid container item>
        <Grid xs={4} item>
          <Label>
            <h3>Персональные данные</h3>
          </Label>
        </Grid>
      </Grid>

      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          fullWidth
          required
          label={locale.user.surname}
          name="surname"
        />
      </Grid>

      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          fullWidth
          required
          label={locale.user.name}
          name="name"
        />
      </Grid>

      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          fullWidth
          required
          label={locale.user.fathersName}
          name="fathersName"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationSelectField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.sex}
          options={sexOptions}
          placeholder="Выберите значение"
          name="sex"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationDatePickerField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.birthdate}
          name="birthdate"
        />
      </Grid>
      <Grid xs={4} item>
        <MaskValidationTextInput
          mask={MaskExpress.snils}
          required
          fullWidth
          label={locale.user.snils}
          name="snils"
          {...validationFieldContext}
        />
      </Grid>
      <Grid xs={4} item>
        <MaskValidationTextInput
          mask={MaskExpress.phone}
          required
          fullWidth
          label={locale.user.phoneNumber}
          name="phoneNumber"
          {...validationFieldContext}
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label="Email"
          name="email"
        />
      </Grid>

      <Grid container item>
        <Grid xs={4} item>
          <Label>
            <h3>Роль в системе</h3>
          </Label>
        </Grid>
      </Grid>

      <Grid xs={4} item>
        <ValidationSelectField
          validationFieldContext={validationFieldContext}
          required
          label={locale.user.roleName}
          name="roleName"
          options={rolesOptions}
          onChangeValue={onRoleChange}
          placeholder="Выберите значение"
        />
      </Grid>

      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.position}
          name="position"
        />
      </Grid>

      <Grid xs={4} item>
        <Label required>{locale.user.organization}</Label>
        <OrganizationSelect
          validationFieldContext={validationFieldContext}
          name="organization"
        />
      </Grid>

      <Grid xs={4} item>
        <ValidationSelectField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.profile}
          name="profileIds"
          multiple={isMultuSelectProfiles}
          options={profilesOptions}
        />
      </Grid>

      <Grid container item>
        <Grid xs={4} item>
          <Label>
            <h3>Вход в систему</h3>
          </Label>
        </Grid>
      </Grid>

      <Grid xs={4} item />

      <Grid xs={8} item>
        <Button onClick={onGeneratePassword}>Сгенерировать пароль</Button>
      </Grid>

      <Grid
        xs={4}
        item
        sx={{
          padding: "0 0 0 16px !important",
        }}
      >
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          id="textLoginId"
          required
          fullWidth
          label={locale.user.login}
          name="login"
          disabled={true}
        />
        <Button onClick={copyLoginClipboard}>Копировать</Button>
      </Grid>

      <Grid
        xs={4}
        item
        sx={{
          padding: "0 0 0 16px !important",
        }}
      >
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          id="textPasswordId"
          required
          fullWidth
          label={locale.user.password}
          name="password"
        />
        <Button onClick={copyPasswordClipboard}>Копировать</Button>
      </Grid>

      <Grid container item>
        <Grid xs={4} item>
          <SwitchField
            isOn={user?.hasLoginPassAccess}
            label={locale.user.hasLoginPassAccess}
            name="hasLoginPassAccess"
          />
        </Grid>
        <Grid xs={4} item>
          <SwitchField
            isOn={user?.isAccessBlocked}
            label={locale.user.isAccessBlocked}
            name="isAccessBlocked"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
