import { GridSortModel } from "@mui/x-data-grid";
import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";

import { useUsersQuery } from "generated/hooks";
import {
  UserItemFragment,
  UsersQuery,
  UsersQueryVariables,
} from "generated/operations";
import { sortingTransformer } from "../../../../utils/sortingTransformer";
import { whereTransformer } from "../../../../utils/whereTransformer";

import { usersColumnTransformer } from "./utils/usersColumnTransformer";

export type TUsersDataGridProps = Partial<
  UseGqlDataGridParams<UsersQuery, UserItemFragment, UsersQueryVariables>
>;

export type TUsersDataGridResult = UseGqlDataGridResult<
  UsersQuery,
  UserItemFragment,
  UsersQueryVariables
>;

export const useUsersDataGrid = (
  props?: TUsersDataGridProps
): TUsersDataGridResult => {
  const gridName = "users";
  const gridData = useGqlDataGrid<
    UsersQuery,
    UserItemFragment,
    UsersQueryVariables
  >({
    type: gridName,
    queryHook: useUsersQuery,
    sortingTransformer: sortingTransformer(gridName),
    columnTransformer: usersColumnTransformer,
    whereTransformer: whereTransformer(gridName),
    ...props,
  });
  return gridData;
};
