import React, { FC, useMemo } from "react";
import { Formik } from "formik";
import { Drawer, DrawerContent } from "@genome-frontend/uikit/build/lib/uikit";
import { useUpdateUserMutation, useUserQuery } from "generated/hooks";
import { useToasts } from "components/Toast";
import { getInitialValues } from "./getInitialValues";
import { getInputDataFromValues } from "./getInputDataFromValues";
import { namedOperations } from "generated/operations";
import { DrawerForm } from "components/Form";
import { UserUpdateValue } from "pages/UsersPage/types";
import { UserEditFromValidate } from "./UserEditFromValidate";
import { UserEditForm } from "../UserForm";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { UserRole } from "types/UserRole";
import { useSubmitWithCspHandler } from "utils/useSubmitWithCspHandler";
import { useConfirm } from "components/Confirm";
import { formCloseHandler } from "components/Form/formCloseHandler";

interface UserEditDrawerProps {
  id: String;
  onSuccess: (data?: number | undefined) => void;
  onCancel: () => void;
}

export const UserEditDrawer: FC<UserEditDrawerProps> = ({
  id,
  onSuccess,
  onCancel,
}) => {
  const { addSuccessToast, addErrorsToast } = useToasts();
  const { addOkConfirm } = useConfirm();

  const { data, loading } = useUserQuery({
    variables: {
      id,
    },
    onError: () => {
      onCancel();
    },
  });

  const [updateUserMutation, { loading: saving }] = useUpdateUserMutation({
    onCompleted: (payload) => {
      if (payload?.updateUser && payload?.updateUser.errors) {
        addErrorsToast(payload?.updateUser?.errors);
        return;
      } else {
        addSuccessToast("Данные сотрудника изменены");
        onSuccess();
      }
    },
    onError: () => {
      addErrorsToast([{ message: "Ошибка при обновлении данных сотрудника" }]);
    },
    refetchQueries: [namedOperations.Query.Users, namedOperations.Query.User],
  });

  const updateUser = async (values: UserUpdateValue) => {
    const valueClone = { ...values };

    if (values.roleName === UserRole.LawOfficial) {
      if (Array.isArray(valueClone.profileIds) === false) {
        valueClone.profileIds = [valueClone.profileIds as unknown as number];
      }
    }

    console.log(valueClone);

    const input = getInputDataFromValues(valueClone);
    await updateUserMutation({
      variables: {
        userInput: input,
      },
    });
  };

  const { submitWithCspHandler, cspLoading } = useSubmitWithCspHandler({
    next: updateUser,
  });

  const initialValues = useMemo(() => {
    if (!data?.user) {
      return null;
    }
    return getInitialValues(data?.user);
  }, [data]);

  if (!loading && !data?.user) {
    return null;
  }

  if (!initialValues || loading) {
    return (
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            width: 1000,
          },
        }}
        open
        onClose={onCancel}
      >
        <DrawerContent onClose={onCancel} title="Редактирование">
          <CenteredCircularProgress />
        </DrawerContent>
      </Drawer>
    );
  }

  const closeHandler = async (values: UserUpdateValue) =>
    formCloseHandler({ values, initialValues, onCancel, addOkConfirm });

  let values: UserUpdateValue;

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 1000,
        },
      }}
      open
      onClose={() => closeHandler(values)}
    >
      <Formik<UserUpdateValue>
        onSubmit={submitWithCspHandler}
        initialValues={initialValues}
        validate={UserEditFromValidate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => {
          values = props.values;
          return (
            <DrawerForm>
              <DrawerContent
                onClose={() => closeHandler(props.values)}
                title="Редактирование"
                confirmButtonProps={{ disabled: saving || !props.isValid }}
                cancelButtonProps={{
                  onClick: () => closeHandler(props.values),
                }}
              >
                {cspLoading && <CenteredCircularProgress />}
                {data?.user && !cspLoading && (
                  <UserEditForm user={data?.user} />
                )}
              </DrawerContent>
            </DrawerForm>
          );
        }}
      </Formik>
    </Drawer>
  );
};
